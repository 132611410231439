@import 'primeng/resources/primeng.min.css';
@import 'primeflex/primeflex.min';
@import 'primeicons/primeicons.css';
@import './assets/theme.css';
@import './assets/styles/media-queries';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

:root {
  --primary-color: #14378b;
  --primary-color-light: #e7eaf0;
  --off-black: #080808;
  --body-bg-color: #f5f5f5;
  --success-color: #6bf2c2;
  --warn-color: #eee36d;
  --text-warn: #e26060;
  --error-color: #eebf6b;
  --light-blue: #b0b7ca;
  --dark-blue: #091a50;
  --purple: #7e63cf;
  --font-family: 'Open Sans', sans-serif;
  --font-family-headings: 'Ubuntu', sans-serif;
}

body {
  font-family: var(--font-family);
  margin: 0;
  color: #212426;

  > p {
    display: none;
  }
}

.p-dialog-title {
  font-family: var(--font-family-headings);
}

td {
  font-family: var(--font-family);
}

th {
  font-family: 'Ubuntu';
  font-size: 14px !important;
  font-weight: 500 !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-family-headings);
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-weight: 500;
  margin-top: 0;
}

.p-button {
  border-radius: 9px;
  padding: 16px 24px;
  transition: background-color 0.3s;
  padding: 12px 14px;
}

.p-selectbutton {
  .p-button {
    padding: 10px 14px !important;
  }
}

input,
.p-dropdown,
.p-multiselect {
  @extend .w-full;
}

h1 {
  a {
    @extend .text-900, .no-underline;
  }
}

a {
  @extend .font-medium, .text-blue-500;
}

.p-toast-message-text {
  @extend .w-full, .text-sm;
  flex: 1 0 auto !important;
}

.p-toast {
  z-index: 10000 !important;
}

hr {
  @extend .mb-5, .mt-4;
}

.surface-section {
  background-color: var(--body-bg-color);
}

.p-progressbar-value {
  background-color: var(--purple) !important;
}

p-checkbox {
  width: 100%;
  margin-bottom: 10px;
}

.p-checkbox-label {
  margin: 0 0 0 5px !important;
}

.p-calendar,
.p-togglebutton {
  width: 100%;
}

.p-button-icon-only {
  width: 2.357rem !important;
}

p-radiobutton {
  & > label {
    display: inline-block !important;
    margin: 0px 0 0 5px !important;
  }
}

.p-float-label {
  & > label {
    font-size: 14px;
    margin-top: -7px !important;
  }

  .label--error {
    top: 21px;
  }
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 1px;
  font-size: 12px;
  background-color: #f8f9fa;
  padding: 0 5px;
}

.p-component-overlay {
  background-color: rgb(207 207 207 / 40%);
}

.p-datatable-loading-icon:before {
  color: var(--primary-color);
}

.box--container {
  @extend .shadow-2, .p-4;
  background-color: #fff;
}

p-chip {
  font-family: 'Open Sans';

  div {
    padding: 0 0.9rem 0.1rem;
  }

  &.high-contrast {
    div {
      font-weight: 700;
      color: #292929;
    }
  }
  &.pending div {
    background-color: var(--warn-color);
  }

  &.completed div {
    background-color: var(--success-color);
  }

  &.error div {
    background-color: var(--error-color);
  }
}
.pending--italic {
  margin-left: 5px;
  font-family: 'Open Sans';
  font-size: 0.8rem;
  font-style: italic;
}

hr {
  border-top: solid 1px #d7d7d7;
  height: 1px;
  border-bottom: 0;
}

h4 {
  margin-top: 0;
  font-weight: 500;
}

.subhead--label {
  display: block;
  text-transform: uppercase;
  font-weight: 200;
  font-size: 13px;
  margin-bottom: 5px;
  letter-spacing: 0.03rem;
}

h3 {
  font-weight: 400;
}

ul {
  list-style-type: none;
  padding: 0;

  li {
    margin-bottom: 5px;
  }
}

.breadcrumb--button {
  font-size: 14px;
  left: -41px;
  top: -15px;
}

.actions--container {
  border-top: solid 1px #e6e6e6;
  margin-top: 12px;
  padding-top: 12px;
  text-align: center;
}

.border--right {
  border-right: solid 1px #e6e6e6;
}

.small--label label {
  font-size: 14px;
}

.display-none {
  display: none;
}

@media screen and (max-width: 768px) {
  h1 {
    font-size: 1.3rem;
  }

  .p-dialog {
    width: 98% !important;
  }

  .mobile-nav--button {
    .pi {
      font-size: 1.5rem;
    }
  }

  .p-datatable {
    .p-datatable-tbody > tr {
      border: solid 1px #dee2e6;
      display: block;
      margin-bottom: 15px;
    }

    .p-datatable-tbody > tr > td {
      text-align: left;
      display: block;
      width: 100%;
      align-items: flex-start !important;
      border: 0 none;
      flex-direction: column;

      .p-column-title {
        font-weight: bold;
      }
    }

    .mobile-action--button {
      border-top: solid 1px #dee2e6 !important;
      padding-top: 5px !important;
      padding-bottom: 5px !important;

      button {
        margin: 0 auto;
      }
    }
  }
}

.modal--no-padding {
  .p-dialog-content {
    padding: 0;
  }
}

.p-component-overlay-enter {
  animation: p-component-overlay-enter-animation 150ms forwards;
}

.p-component-overlay-leave {
  animation: p-component-overlay-leave-animation 150ms forwards;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(0, 0, 0, 0.4);
  }
}
@keyframes p-component-overlay-leave-animation {
  from {
    background-color: rgba(0, 0, 0, 0.4);
  }
  to {
    background-color: transparent;
  }
}

.p-confirm-dialog {
  .p-dialog-content {
    padding: 24px;
  }

  .p-dialog-footer {
    justify-content: center;
    display: flex;
  }
}

.review--section {
  .p-disabled,
  .p-component:disabled {
    opacity: 1;
  }

  .p-dropdown-trigger {
    display: none;
  }

  .p-float-label:first-child {
    margin-top: 5px !important;
  }

  .grid {
    .p-float-label:first-child {
      margin-top: 25px !important;
    }
  }
}

.p-accordion-header-link {
  color: var(--dark-blue) !important;
}

.box-shadow-none:focus {
  box-shadow: none !important;
}

.error-message {
  color: var(--text-warn);
  font-size: 13px;
  margin: 5px 0 10px 0;
}

.total-spinner .p-progress-spinner {
  width: 1rem;
  height: 1rem;
}
